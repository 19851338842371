.change-conteiner{
    position: relative;

    .show-edit-input-label{
        display: none;
        position: absolute;
        margin-top: -20px;
        left: -25px;
        cursor: pointer;
    }

    .save-input-change-btn{
        display: none;
        position: absolute;
        margin-top: 3px;
        left: -25px;
        cursor: pointer;
    }

    .show-edit-intup-label-title{
        display: none;
        position: absolute;
        margin-top: -23px;
        left: -35px;
    }

    // #change-weight-values-list,
    #weight-input-span,
    #dimensions-input-span{
        float: right;
    }

    &:hover .show-edit-input-label,
    &:hover .show-edit-intup-label-title{
        display: block;
    }
}

#product-notes-container {
    #edit-product-note:hover {
        .icofont-ui-edit {
            color: #448aff;
        }
    }
    #delete-product-note:hover {
        .icofont-delete-alt {
            color: #ff5252;
        }
    }

    #delete-product-note {
        color: #ff5252;
    }

    .user-note {
        border-bottom: 1px solid rgba(0,0,0,.125);
    }

    .edit-note-textarea-btns {
        display: none;
    }

    &:hover .edit-note-textarea-btns .edit-note-save-btns,
    &:hover .edit-note-textarea-btns .edit-note-cancel-btns {
        cursor: pointer;
    }
}

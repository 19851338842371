#e-product-list{
    tbody{
        tr{
            td{
                img{
                    max-width: 50px;
                }
            }
        }
    }
}

select.form-control:not([size]):not([multiple]) {
    height: 2.83rem;
}

.inv-filter-btn{
    &:hover {
        color: #448aff;
        cursor: pointer;
      }
}

.inv-filtered-by{
    color: #448aff;
    font-weight: bold;
}

.inv-filter-reset{
    font-weight: bold;
    &:hover{
      color: #ff5252;
      cursor: pointer;
    }
}

.other_product_numbers{
    font-size: .7em;
}

#register_container {
    margin: -1.5rem !important;
}

#register_left {
    background-color: #074cd5;
    padding: 40px 100px;
    // padding-left: 70px;
    color: #ffffff;
    min-height: 100vh;
}

#register_left_logo img {
    width: 70px;
}

#register_left #logo_title {
    font-weight: bold;
    font-size: 2em;
}

#register_left #logo_slogan {
    margin-top: -1.5em;
}

#register_left #register_left_imagine_text {
    font-size: 2em;
}

#register_left #register_left_imagine_text span{
    color: #000000;
    font-weight: bold;
}

#register_right {
    background-color: #ffffff;
    // padding: 40px 100px;
    min-height: 100vh;
}

#register_right button{
    width: 100%;
}

#register_right .login-link {
    color: #074cd5;
    font-weight: bold;
}

#register_right #email_icon .fa,
#register_right #password_icon .fa {
    font-size: 30px;
}
